import axios from 'axios';

export function getProfile() {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data;
    const config = {
        headers: {
            'Authorization': token,
            'accept': '*/*'
        }
    };

    return axios.get(
        `https://core.pskeromais.com.br/api/v1/get-profile`,
        config
    );
}

export function getProfileInfos() {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data;
    const config = {
        headers: {
            'Authorization': token,
            'accept': '*/*'
        }
    };

    const postData = {
        "StoreId": dumbToken.profileId
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreRequest`,
        postData, 
        config
    );
}

export function editProfileInfos(profileData) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data;
    const config = {
        headers: {
            'Authorization': token,
            'accept': '*/*'
        }
    };

    const postData = {
        "Id": profileData.id,
        "Name": profileData.name,
        "Email": profileData.email,
        "Phone": profileData.phone,
        "TaxId": profileData.taxId
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/UpdateStoreCommand`,
        postData,
        config
    );
}

export function createAddress(Street, Number, Neighborhood, City, State, ZipCode) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));

    const token = 'Bearer ' + dumbToken.data;
    
    const config = {
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    };

    const postData = {
        "Street": Street,
        "Number": Number,
        "Neighborhood": Neighborhood,
        "City": City,
        "State": State,
        "ZipCode": ZipCode,
        "Country": "Brasil",
        "Latitude": 1,
        "Longitude": 1
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/AddressCommand`,
        postData, 
        config
    );
}

export function createImg(id , name , position , isMain , base64 ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data;
    
    const config = {
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    };

    const postData = {
        "PersonId": id,
        "Name": name,
        "Position": position,
        "IsMain": isMain,
        "Base64": base64
      }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/ImageCommand`,
        postData, 
        config
    );
}