import React, { useState, useEffect } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { RetrieveOrder , orderConfirm , orderRefuse , orderDelivery , orderDone } from '../../services/OrderService';
import tabData from "./subtabs/tabDataPedidos.json"
import { extractDate, extractTime, formatPhoneNumber, formatCNPJ, formatCPF } from '../helpers/helpers';

const statusMap = {
    0: 'Pendente',
    1: 'Confirmado',
    2: 'Enviado',
    3: 'Entregue',
    4: 'Cancelado',
    5: 'Rejeitado'
};

function Order() {
    const [order, setOrder] = useState({});
    const [toggle, setToggle] = useState(false);
    const [orderTaken, setOrderTaken] = useState({});
    const [refuseModal, setRefuseModal] = useState(false);
    const [acceptModal, setAcceptModal] = useState(false);
    const [deliveryModal, setDeliveryModal] = useState(false);
    const [doneModal, setDoneModal] = useState(false);
    const [isDeliveryChecked, setIsDeliveryChecked] = useState(false);
    const [isDoneChecked, setIsDoneChecked] = useState(false);

    const setModal = (type) => {
        if (type === 'accept') {
            setAcceptModal(true)
        } else if (type === 'refuse') {
            setRefuseModal(true)
        } else if (type === 'delivery') {
            setDeliveryModal(true)
        } else if (type === 'done') {
            setDoneModal(true)
        }
    }

    const handlerOrder = (id, type) => {
        if (type === 'accept') {
            const confirmOrderFunc = async () => {
                try {
                    const fetchConfirm = async () => {
                        try {
                            const response = await orderConfirm(id);
                            if (response.status === 200) {
                                setToggle(false)
                                setAcceptModal(false)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchConfirm();

                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            };
            confirmOrderFunc()
        } else if (type === 'refuse') {
            const refuseOrderFunc = async () => {
                try {
                    const fetchRefuse = async () => {
                        try {
                            const response = await orderRefuse(id);
                            if (response.status === 200) {
                                setToggle(false)
                                setRefuseModal(false)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchRefuse();

                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            } 
            refuseOrderFunc()
        } else if (type === 'delivery') {
            const refuseOrderFunc = async () => {
                try {
                    const fetchRefuse = async () => {
                        try {
                            const response = await orderDelivery(id);
                            if (response.status === 200) {
                                setToggle(false)
                                setDeliveryModal(false)
                                setIsDeliveryChecked(true)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchRefuse();

                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            } 
            refuseOrderFunc()
        } else if (type === 'done') {
            const refuseOrderFunc = async () => {
                try {
                    const fetchRefuse = async () => {
                        try {
                            const response = await orderDone(id);
                            if (response.status === 200) {
                                setDoneModal(false)
                                setToggle(false)
                                setIsDoneChecked(true)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchRefuse();

                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            } 
            refuseOrderFunc()
        }
    }

    useEffect(() => {
        if (!toggle) {
            const checkOrder = async () => {
                try {
                    const params = new URLSearchParams(window.location.search);
                    const pValue = params.get('p');
                    if (pValue) {
                        const response = await RetrieveOrder(pValue);
                        if (response.status === 200) {
                            setOrder(response.data.order);
                        } else {
                            console.error('Erro ao obter os produtos:', response.statusText);
                        }
                    }
                } catch (error) {

                }
            };
            checkOrder();
            setToggle(true)
        }
    }, [toggle]);

    useEffect(() => {
        console.log('order')
        console.log(order)
    }, [order])

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <div className="row">
                <div className="col-xl-8 col-xxl-12">
                    {order.number ?
                        <div className="h-auto b-hover">
                            <div className="p-3"
                                style={{
                                    boxShadow: '2px 2px 2px #f1f1f1',
                                }}>
                                <div className="d-flex flex-row" style={{ justifyContent: "space-between" }}>
                                    <div className="text-left">
                                        <h3 style={{ marginBottom: 1 }}>Pedido Nº {order.number}</h3>
                                        <p style={{ marginBottom: 1 }}><i className="bi bi-calendar-date text-primary" style={{ marginRight: 4 }}></i> {extractDate(order.orderDate)} | <i className="bi bi-clock  text-primary" style={{ marginRight: 4 }}></i> {extractTime(order.createDate)}</p>
                                        <h3 style={{ marginBottom: 1 }}>{order.customer.name}</h3>
                                        <p style={{ marginBottom: 1 }}><i className="bi bi-person-badge text-primary" style={{ marginRight: 3 }}></i> {formatCPF(order.customer.taxId)} | <i className="bi bi-telephone-fill  text-primary"></i> {formatPhoneNumber(order.customer.phone)}</p>
                                    </div>
                                    <div style={{ display: 'flex', gap: 5, paddingTop: 20, flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 3, padding: 3, backgroundColor: 'rgb(234, 234, 234)', borderRadius: 6 }}>
                                            <label className="mb-0">status</label>
                                            <h4 className="mb-0 text-primary">{statusMap[order.orderStatus]}</h4>
                                        </div>
                                        {order.orderStatus === 0 ?
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                                <a className='d-flex btn btn-primary' onClick={() => setModal('accept')} style={{ padding: '3px 10px', maxHeight: 40, alignItems: 'center' }}>
                                                    ACEITAR
                                                </a>
                                                <a className='d-flex btn btn-secondary' onClick={() => setModal('refuse')} style={{ padding: '3px 10px', maxHeight: 40, alignItems: 'center' }}>
                                                    RECUSAR
                                                </a>
                                            </div>:null}
                                        {order.orderStatus == 1 ?
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 6 , paddingTop: 6 }}>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" onClick={() => setModal('delivery')} type="checkbox" id="saiuParaEntrega" checked={isDeliveryChecked}/>
                                                    <label className="form-check-label" htmlFor="saiuParaEntrega">Saiu para Entrega</label>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="entregue" checked={isDoneChecked}/>
                                                    <label className="form-check-label" htmlFor="entregue">Entregue</label>
                                                </div>
                                            </div>
                                        :  order.orderStatus === 2 ?
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 6 , paddingTop: 6 }}>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="saiuParaEntrega" checked={true}/>
                                                    <label className="form-check-label" htmlFor="saiuParaEntrega">Saiu para Entrega</label>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" onClick={() => setModal('done')} type="checkbox" id="entregue" checked={isDoneChecked}/>
                                                    <label className="form-check-label" htmlFor="entregue">Entregue</label>
                                                </div>
                                            </div>
                                        :  order.orderStatus === 3 ?
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 6 , paddingTop: 6 }}>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input"  type="checkbox" id="saiuParaEntrega" checked={true}/>
                                                <label className="form-check-label" htmlFor="saiuParaEntrega">Saiu para Entrega</label>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="entregue" checked={true}/>
                                                <label className="form-check-label" htmlFor="entregue">Entregue</label>
                                            </div>
                                        </div>:null}
                                    </div>
                                </div>
                                <hr />

                                <h4><i className="fa-solid fa-location-dot text-primary" style={{ marginRight: 7 }}></i> {order.address.street + ' ( ' + order.address.zipCode + ' ) ' + ', Nº ' + ' , ' + order.address.city + ' - ' + order.address.state}</h4>
                                <hr />
                                <div className="order-menu">
                                    <h6>Itens</h6>
                                    {
                                        order.orderItems.map((a, b) => (

                                            <div key={'orderItems-' + b} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
                                                <div style={{ display: 'flex' }}>
                                                    <img src={'https://core.pskeromais.com.br/' + a.product.images[0].url} />
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                                        <p style={{ fontSize: 18, marginBottom: 0, marginTop: 0, height: 27 }}>{a.product.name}</p>
                                                        <b className="text-primary">{a.quantity + ' x'}</b>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 5 }}>
                                                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <label style={{ marginRight: 5, fontSize: 9 }}>unit</label>
                                                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: 10, borderBottom: '1px dotted rgb(182 182 182', paddingBottom: 4 }}>
                                                            {
                                                                a.product.salePrice ?
                                                                    <i style={{ margin: '0 4px', textDecoration: 'line-through', color: '#a0a0a0' }}>
                                                                        {a.product.salePrice.toFixed(2) + ' R$'}
                                                                    </i> : null
                                                            }
                                                            <b className="text-primary" >
                                                                {' ' + a.unitPrice.toFixed(2) + ' R$'}
                                                            </b>
                                                        </span>
                                                    </span>
                                                    <span style={{ marginTop: 5}}>
                                                        <label style={{ marginRight: 5, fontSize: 9 }}>total</label>
                                                        <b className="text-primary">
                                                            {' ' + a.total.toFixed(2) + ' R$'}
                                                        </b>
                                                    </span>
                                                </div>
                                            </div>

                                        )
                                        )
                                    }
                                    <hr />
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <h4 className="mb-0">Total do pedido</h4>
                                            <span style={{ display: 'flex', gap: 5, marginBottom: 7, marginTop: 4 }}>
                                                <span style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: 6, backgroundColor: 'rgb(234 234 234)' }}>
                                                    <label style={{ fontSize: 12, margin: 0 }}>entrega</label>
                                                    <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>R$ {' ' + order.deliveryRate.toFixed(2)}</span>
                                                </span>
                                                <span style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: 6, backgroundColor: 'rgb(234 234 234)' }}>
                                                    <label style={{ fontSize: 12, margin: 0 }}>compra total</label>
                                                    <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>R$ {' ' + order.totalItems.toFixed(2)}</span>
                                                </span>
                                                <span style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: 6, backgroundColor: 'rgb(234 234 234)' }}>
                                                    <label style={{ fontSize: 12, margin: 0 }}>desconto</label>
                                                    <span className="text-primary" style={{ fontSize: 15, margin: 0 }}>R$ {' ' + order.discount.toFixed(2)}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2 }}>
                                            <h4 className="mb-0">Total</h4>
                                            <h2 className="m-0 text-primary">R$ {' ' + (order.totalAmount + order.deliveryRate).toFixed(2)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : 'pedido não encontrado'}
                </div>
            </div>
            <Modal id="exampleModal" show={acceptModal} onHide={setAcceptModal} centered>
                <button onClick={() => setAcceptModal(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <i className="fa-regular fa-circle-check" style={{ fontSize: 30 }}></i>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1' }}>Aceitar Pedido?</h4>
                    <br />
                    <div className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => handlerOrder(order.id , 'accept')} className='btn btn-primary'>
                            aceitar pedido
                        </button>
                        <button onClick={() => setAcceptModal(false)} className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal id="exampleModal" show={refuseModal} onHide={setRefuseModal} centered>
                <button onClick={() => setRefuseModal(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <i className="fa-regular fa-circle-xmark" style={{ fontSize: 30 }}></i>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}>Recusar Pedido?</h4>
                    <br />
                    
                    <div className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => handlerOrder(order.id , 'refuse')} className='btn btn-primary'>
                            recusar pedido
                        </button>
                        <button onClick={() => setRefuseModal(false)} className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal id="exampleModal" show={deliveryModal} onHide={setDeliveryModal} centered>
                <button onClick={() => setDeliveryModal(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <i className="fa-regular fa-circle-check" style={{ fontSize: 30 }}></i>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1' }}>Confirmar entrega</h4>
                    <br />
                   
                    <div className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => handlerOrder(order.id , 'delivery')} className='btn btn-primary'>
                            confirmar entrega
                        </button>
                        <button onClick={() => setDeliveryModal(false)} className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal id="exampleModal" show={doneModal} onHide={setDoneModal} centered>
                <button onClick={() => setDoneModal(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <i className="fa-regular fa-circle-xmark" style={{ fontSize: 30 }}></i>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}>Confirmar entrega</h4>
                    <br />
                    <div className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => handlerOrder(order.id , 'done')} className='btn btn-primary'>
                            pedido entregue
                        </button>
                        <button onClick={() => setDoneModal(false)} className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Order;